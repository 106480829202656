import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

// Extend dayjs with customParseFormat plugin to parse custom date formats
dayjs.extend(customParseFormat)

export const militaryToStandard = (militaryTime: string) => {
	if (militaryTime.includes('AM') || militaryTime.includes('PM')) return militaryTime
	const sliptTime = militaryTime.split(':')

	const hours = Number(sliptTime[0])
	const minutes = Number(sliptTime[1])
	const seconds = Number(sliptTime[2])

	let timeValue = ''
	if (hours > 0 && hours <= 12) timeValue = `${hours}`
	else if (hours > 12) timeValue = `${hours - 12}`
	else if (hours === 0) timeValue = '12'

	timeValue += minutes < 10 ? `:0${minutes}` : `:${minutes}`
	if (seconds) timeValue += seconds < 10 ? `:0${seconds}` : `:${seconds}`
	timeValue += hours >= 12 ? ' PM' : ' AM'
	return timeValue
}
export const dateYMDdashToMDYslash = (ymd: string) => {
	const [year, month, day] = ymd.split('-')
	return `${month}/${day}/${year}`
}
const addPrecedingZero = (item: string) => {
	if (item.length < 2) {
		return `0${item}`
	}
	return item
}
/**
 * Adds a preceding 0 on single digit months and days
 * @param date date string in Y-M-D format
 * @returns string [YYYY-MM-DD]
 */
export const formatDateStringToYMD = (date: string) => {
	const [year, month, day] = date.split('-').map(addPrecedingZero)
	return `${year}-${month}-${day}`
}
/**
 * Takes date object and returns a string in YYYY-MM-DD format
 * @param date date object defaults to current date
 * @returns string [YYYY-MM-DD]
 */
export const dateObjToDateStrDash = (targetDate?: Date) => {
	let date = targetDate
	if (!date) {
		if (process.env.REACT_APP_DATE) date = dateStringToDateObj(process.env.REACT_APP_DATE)
		else date = new Date()
	}
	return formatDateStringToYMD(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`)
}

export const dateStringToDateObj = (dateString: string) => {
	const inputString =
		dateString.indexOf('-') >= 0 ? dateYMDdashToMDYslash(dateString) : dateString
	return new Date(inputString)
}

export const convertTimeStringToMinutes = (time: string) => {
	const timeParts = time.split(':')
	return parseInt(timeParts[0], 10) * 60 + parseInt(timeParts[1], 10)
}

export const addMinutesToTimeString = (time: string, addedMinutes: number) => {
	const startTime = convertTimeStringToMinutes(time)
	const newTime = startTime + addedMinutes
	return convertMinutesToTimeString(newTime)
}

export const convertMinutesToTimeString = (minutes: number) => {
	const hours = Math.floor(minutes / 60)
	const remainingMinutes = minutes % 60
	const hoursString = hours < 10 ? `0${hours}` : `${hours}`
	const minutesString = remainingMinutes < 10 ? `0${remainingMinutes}` : `${remainingMinutes}`
	return `${hoursString}:${minutesString}`
}

export const dayjsMilitaryToStandard = (time: string) => {
	// Ensure dayjs can parse standard time formats like "8:00 AM"
	const parsedTime = dayjs(time, 'h:mm A')
	if (!parsedTime.isValid()) {
		return 'Invalid Date'
	}
	// Format the time to standard time format "h:mm A"
	return parsedTime.format('h:mm A')
}
